import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AssayDetailsApiService} from '../../../../services/api/assay-details-api/assay-details-api.service';

@Component({
  selector: 'app-drug-combination',
  templateUrl: './drug-combination.component.html',
  styleUrls: ['./drug-combination.component.scss']
})
export class DrugCombinationComponent implements OnInit {
  public drugId!: any;
  public error = false;
  public drug: any;
  public drugCombinations: any[] = [];
  constructor(private route: ActivatedRoute, private drugNameApi: AssayDetailsApiService) {}

  ngOnInit(): void {
    this.drugId = this.route.snapshot.pathFromRoot['4'].params['id'];
    this.error = false;
    this.drugNameApi.getSingleAssay(this.drugId).subscribe(
      (data: any[]) => {
        this.drug = data[0];
        this.drugNameApi.getMatrixbyID(data[0].matrixFileId).subscribe((data: any[]) => {
          this.drugCombinations = data;
        });
      },
      () => (this.error = true)
    );
  }
}
