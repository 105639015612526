import {Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {forkJoin, Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {DatasetsApiService} from '../../services/api/datasets-api-graphql/datasets-api.service';
import {AssayApiService} from '../../services/api/assay-api/assay-api.service';
import {DatasetMetadataApiService} from '../../services/api/dataset-metadata-api/dataset-metadata-api.service';
import {VariantDataset} from '../../models/dtos/variant/dataset';
import {AssayOverview} from '../../models/dtos/assay/assay-overview';
import {DatasetFilters} from '../../models/dtos/variant/dataset-filters';
import {API_URLS, Endpoints} from '@odp/shared';
import {FilterStateService} from '../../services/api/datasets-api-graphql/filter-state.service';
import moment from 'moment';
import {Moment} from 'moment';
import {NgxDaterangepickerMd, DaterangepickerDirective} from 'ngx-daterangepicker-material';

interface DateRange {
  startDate: Moment;
  endDate: Moment;
}
@Component({
  selector: 'app-datasets',
  templateUrl: './datasets.component.html',
  styleUrls: ['./datasets.component.scss']
})
export class DatasetsComponent implements OnInit, OnDestroy {
  private filterStateSubscription: Subscription;
  dateRange: DateRange | null = null;

  constructor(
    private variantApi: DatasetsApiService,
    private route: ActivatedRoute,
    private titleService: Title,
    @Inject(API_URLS) private configuration: Endpoints,
    public assayApi: AssayApiService,
    private dataMetaApi: DatasetMetadataApiService,
    private filterStateService: FilterStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.filterStateSubscription = new Subscription();
    this.apiBaseUrl = this.configuration.variantApiUrl;
  }

  public variantBaseUrl!: string;
  public dataset!: VariantDataset[];
  public searchResult!: VariantDataset[];
  public result!: VariantDataset[];
  public expandedTable!: Map<VariantDataset, boolean>;
  public viewSource!: Map<VariantDataset, boolean>;
  public keyWords = '';
  public display = false;
  public mutationDisplay = false;
  public noResult = false;
  public query!: string;
  public andQuery: any;
  public orQuery: any;
  public idQuery: any;
  public displayCopied = false;
  public OOSDisplay = false;
  public DSDisplay = true;
  public mutationExpanded = false;
  public mutationQuery!: string;
  public dateExpanded = false;
  public OOSExpanded = false;

  copyToClipboard!: string;
  diffDay!: number;
  latestUpdate!: Date;
  public dataSourceCount!: number;
  public vlList: string[] = [];
  public filterData: Map<string, string[]> = new Map<string, string[]>();
  public filterColumn: string[] = [
    'Viral Lineage',
    'Viral Type',
    'Assay Type',
    'Therapeutic Name',
    'Therapeutic Class',
    'Data Provider',
    'Data Source Type'
  ];
  public filterColumnWithSearch: string[] = ['Viral Lineage', 'Therapeutic Name', 'Data Provider'];
  public assays: AssayOverview[] = [];
  public filterArrays: any[] = [];
  public filterExpanded: Map<string, boolean> = new Map<string, boolean>();
  public mutationFilter: string[] = [];
  public mutationFilterAutoComplete: string[] = [];
  public filterOnSelect: Map<string, string[]> = new Map<string, string[]>();
  // need 2nd one to re-initialized the filterOnSelect without calling endpoint each time
  public filterOnSelectOrig: Map<string, string[]> = new Map<string, string[]>();
  public filterSelectionData!: {data: DatasetFilters};
  public filterAfterSearch: Map<string, any[]> = new Map<string, any[]>();
  public error = false;
  public recordCount!: number;
  dataLoading = true;
  filterLoaded = false;
  public initialDataset: VariantDataset[] = [];

  public colorList = [
    '#1f77b4',
    '#aec7e8',
    '#ff7f0e',
    '#ffbb78',
    '#2ca02c',
    '#98df8a',
    '#d62728',
    '#ff9896',
    '#9467bd',
    '#c5b0d5',
    '#8c564b',
    '#c49c94',
    '#e377c2',
    '#f7b6d2',
    '#7f7f7f',
    '#c7c7c7',
    '#bcbd22',
    '#dbdb8d',
    '#17becf',
    '#9edae5'
  ];
  public currentPage!: number;
  public pageSize!: number;
  public idList!: number[];
  public totalVariants: number = 0;
  public currentSearchQuery: string = '';
  @ViewChild('pickerRange') pickerRange!: DaterangepickerDirective;
  public apiBaseUrl!: string;

  ngOnInit(): void {
    this.dataLoading = true;
    this.currentPage = 1;
    this.pageSize = 20;
    this.idList = [];
    this.titleService.setTitle('Variant Therapeutic in vitro Dataset Browser');
    this.filterOnSelect = new Map<string, string[]>(); // Initialize filterOnSelect as a new Map
    this.filterStateSubscription = this.filterStateService.getFilterStateObservable().subscribe((state) => {
      this.dateRange = state.get('dateRange') || this.dateRange; // Preserve existing dateRange if it exists
    });
    this.filterColumn.forEach((col) => {
      this.filterExpanded.set(col, false);
    });

    this.filterExpanded.set('Report Date Range', false);
    this.mutationExpanded = false;
    this.dateExpanded = false;

    this.route.queryParams.subscribe((params) => {
      if (params) {
        const filterState = new Map<string, string[]>();
        const searchQuery = params['search_query'] || '';

        this.filterColumn.forEach((col) => {
          const paramKey = col.toLowerCase().replace(/ /g, '_');
          if (params[paramKey]) {
            filterState.set(col, params[paramKey].split(','));
          }
        });

        const startOfDatepicker = moment('2020-01-01');
        const today = moment();
        // start with 2020 up until last month as default
        this.dateRange = {
          startDate: startOfDatepicker,
          endDate: today
        };
        // but reset if params are passed in
        if (params['start_date'] || params['end_date']) {
          this.dateRange = {
            startDate: params['start_date'] ? moment(params['start_date']) : moment(),
            endDate: params['end_date'] ? moment(params['end_date']) : moment()
          };
        }

        this.filterOnSelect = filterState;
        this.currentSearchQuery = searchQuery;
        this.loadInitialVariants();
      }
    });
  }

  ngOnDestroy() {
    this.filterStateSubscription.unsubscribe();
  }

  deepCopyMap(originalMap: Map<string, string[]>): Map<string, string[]> {
    const newMap = new Map<string, string[]>();
    originalMap.forEach((value, key) => {
      newMap.set(key, [...value]);
    });
    return newMap;
  }

  initializeFiltersFromQueryParams(params: any) {
    Object.keys(params).forEach((key) => {
      if (this.filterColumn.map((col) => col.toLowerCase().replace(/ /g, '_')).includes(key)) {
        const filterValues = params[key].split(',');
        const originalKey = this.filterColumn.find((col) => col.toLowerCase().replace(/ /g, '_') === key);
        if (originalKey) {
          this.filterOnSelect.set(originalKey, filterValues);
        }
      } else if (key === 'search_query') {
        this.currentSearchQuery = params[key];
        this.query = params[key];
      } else if (key === 'date_range') {
        const dateRange = JSON.parse(params[key]);
        this.dateRange = {
          startDate: moment(dateRange.startDate),
          endDate: moment(dateRange.endDate)
        };
      }
    });

    this.filterStateService.setFilterState(this.objectToMap(this.filterOnSelect));
  }

  loadInitialVariants() {
    this.dataLoading = true;

    const requestBody = {
      page: this.currentPage,
      page_size: this.pageSize,
      id_list: JSON.stringify(this.idList),
      date_range: this.dateRange || null,
      search_query: this.currentSearchQuery // Include search_query in initial load
    };

    const getDataset$ = this.variantApi.getVariantDatasets(requestBody);
    const getAssayOverview$ = this.assayApi.getAllAssayOverviews();
    const getFilterData$ = this.variantApi.getDatasetFilters();
    const getRecord$ = this.dataMetaApi.getRecordCount();
    const getParams$ = this.route.queryParams;

    forkJoin([getDataset$, getFilterData$, getRecord$, getAssayOverview$, getParams$.pipe(first())]).subscribe(
      ([DSresult, filterData, record, assayoverviews, params]) => {
        this.dataset = DSresult.data;
        this.initialDataset = this.dataset;
        this.totalVariants = DSresult.total;
        this.recordCount = DSresult.total;
        this.searchResult = this.dataset;
        this.result = this.dataset;
        this.assays = assayoverviews;
        this.expandedTable = new Map<VariantDataset, boolean>(this.result.map((x) => [x, false]));
        this.viewSource = new Map<VariantDataset, boolean>(this.result.map((x) => [x, false]));
        this.filterSelectionData = filterData;
        this.loadFilterData();
        this.getDiffDay();
        this.dataLoading = false;

        // Set filterOnSelectOrig after fetching the initial filter data
        this.filterOnSelectOrig = new Map<string, string[]>();
        this.filterColumn.forEach((col) => {
          const key = col.toLowerCase().replace(/ /g, '_') as keyof DatasetFilters;
          this.filterOnSelectOrig.set(col, filterData.data[key] || []);
        });

        // Process query params
        this.processQueryParams(params);
      },
      (error: any) => {
        console.error('Error occurred during datasets forkJoin data fetching: ', error);
        this.error = true;
      }
    );
  }

  loadVariants(resetPage: boolean = false) {
    if (resetPage) {
      this.currentPage = 1; // Reset paginator to the first page
    }

    this.dataLoading = true;

    const filters: {[key: string]: string[]} = {
      viral_lineage: this.filterOnSelect.get('Viral Lineage') ?? [],
      viral_type: this.filterOnSelect.get('Viral Type') ?? [],
      assay_type: this.filterOnSelect.get('Assay Type') ?? [],
      drug_name: this.filterOnSelect.get('Therapeutic Name') ?? [],
      drug_class: this.filterOnSelect.get('Therapeutic Class') ?? [],
      data_provider: this.filterOnSelect.get('Data Provider') ?? [],
      data_source_type: this.filterOnSelect.get('Data Source Type') ?? [],
      mutation: this.filterOnSelect.get('Mutation') ?? []
    };

    const currentFilterState = this.filterStateService.getFilterState();
    this.dateRange = currentFilterState.get('dateRange') || this.dateRange; // Preserve existing dateRange

    const requestBody = {
      ...filters,
      search_query: this.currentSearchQuery, // Use currentSearchQuery
      page: this.currentPage,
      page_size: this.pageSize,
      id_list: JSON.stringify(this.idList),
      date_range: this.dateRange
        ? {
            startDate: this.dateRange.startDate.format('YYYY-MM-DD'),
            endDate: this.dateRange.endDate.format('YYYY-MM-DD')
          }
        : null
    };

    this.variantApi.getVariantDatasets(requestBody).subscribe(
      (DSresult) => {
        this.dataset = DSresult.data; // Only replace with the new set of results
        this.searchResult = this.dataset;
        this.result = this.dataset;
        this.totalVariants = DSresult.total;
        this.expandedTable = new Map<VariantDataset, boolean>(this.result.map((x) => [x, false]));
        this.viewSource = new Map<VariantDataset, boolean>(this.result.map((x) => [x, false]));
        this.getTotalDatasetCount();
        this.getDiffDay();
        this.dataLoading = false;
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        console.error('Error occurred during loadVariants', error);
        this.error = true;
        this.dataLoading = false;
      }
    );
  }

  loadFilterData() {
    this.initalizeFilterData();
    this.initalizeFilterOnSelect();
    this.loadColumnsFilterData('Viral Lineage', this.filterSelectionData.data.lineages);
    this.loadColumnsFilterData('Viral Type', this.filterSelectionData.data.viralTypes);
    this.loadColumnsFilterData('Assay Type', this.filterSelectionData.data.assayTypes);
    this.loadColumnsFilterData('Data Provider', this.filterSelectionData.data.dataProviders);
    this.loadColumnsFilterData('Therapeutic Name', this.filterSelectionData.data.therapeuticAgents);
    this.loadColumnsFilterData('Therapeutic Class', this.filterSelectionData.data.therapeuticClasses);
    this.loadColumnsFilterData('Data Source Type', this.filterSelectionData.data.dataSourceTypes);
    this.loadMutation(this.filterSelectionData.data.mutations);
    this.filterLoaded = true;
  }

  getDiffDay() {
    const allDates: Date[] = [];
    const todayDate = new Date();
    this.dataset.forEach((item) => {
      allDates.push(new Date(item.dateUploaded));
    });

    if (allDates.length > 0) {
      this.latestUpdate = allDates.reduce((a, b) => (a > b ? a : b));
    } else {
      this.latestUpdate = todayDate;
    }

    const times = Math.abs(this.latestUpdate.getTime() - todayDate.getTime());
    this.diffDay = Math.ceil(times / (1000 * 60 * 60 * 24));
  }

  getTotalDatasetCount() {
    this.dataSourceCount = this.dataset.length;
  }

  clearDateRange(): void {
    this.dateRange = null;

    const currentFilterState = this.filterStateService.getFilterState();
    const updatedState = new Map(currentFilterState);
    updatedState.delete('dateRange');
    this.filterStateService.setFilterState(updatedState);

    this.loadVariants(true); // Reset paginator and load variants
  }

  loadMutation(mutation: string[]) {
    for (const vl of mutation) {
      this.mutationFilter.push(vl);
    }
  }

  filterOnChange(data: string, col: string) {
    try {
      const dataList = this.filterOnSelect.get(col) ?? [];
      if (dataList.includes(data)) {
        const i = dataList.indexOf(data);
        dataList.splice(i, 1);
      } else {
        dataList.push(data);
      }
      this.filterOnSelect.set(col, dataList);
      this.filterStateService.setFilterState(this.objectToMap(this.filterOnSelect));
      this.loadVariants(true);

      // Update the search filter selections to reflect changes in selection
      this.searchFilterSelections(col, '');
    } catch (error) {
      console.error('Error in filterOnChange', error);
    }
  }

  handleEnterKey() {
    const applyButton = document.getElementById('hidden-apply-button') as HTMLButtonElement;
    if (applyButton) {
      applyButton.click();
    }
  }

  submitDateRange(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.changeDate({
        startDate: this.dateRange?.startDate,
        endDate: this.dateRange?.endDate
      });
      this.pickerRange.hide(); // Manually hide the picker
    }
  }

  changeDate(event: any): void {
    let startDate: Moment | null = null;
    let endDate: Moment | null = null;

    // Check if startDate and endDate are Day.js objects, convert them to Moment.js objects
    if (event.startDate && event.endDate) {
      if (event.startDate.isValid && event.endDate.isValid) {
        startDate = moment(event.startDate.toDate());
        endDate = moment(event.endDate.toDate());
      } else {
        startDate = moment(event.startDate);
        endDate = moment(event.endDate);
      }
    } else if (event.target && event.target.value) {
      const dateRangeString = event.target.value;

      if (dateRangeString) {
        const dates = dateRangeString.split(' - ');

        if (dates.length === 2) {
          startDate = moment(dates[0], 'MM/DD/YYYY');
          endDate = moment(dates[1], 'MM/DD/YYYY');
        }
      }
    }

    if (startDate && endDate && startDate.isValid() && endDate.isValid()) {
      this.dateRange = {
        startDate,
        endDate
      };

      const currentFilterState = this.filterStateService.getFilterState();
      const updatedState = new Map(currentFilterState);
      updatedState.set('dateRange', this.dateRange);

      this.filterStateService.setFilterState(updatedState);

      this.loadVariants(true); // Reset paginator and load variants
    }
  }

  addMutationFilter(value: string) {
    this.mutationDisplay = false;
    const dataList = this.filterOnSelect.get('Mutation');
    if (!!dataList && !dataList.includes(value)) {
      dataList.push(value);
    }
    this.filterStateService.setFilterState(this.objectToMap(this.filterOnSelect));
    this.loadVariants(true);
  }

  getSearchResult(query: string) {
    if (!query) {
      return;
    }
    this.keyWords = query;
    this.currentSearchQuery = query; // Set the search query
    this.loadVariants(true);
  }

  searchDateRange(dateRange: DateRange | null, reportDate: string): boolean {
    if (!dateRange) return true;
    const reportMoment = moment(reportDate);
    return reportMoment.isBetween(dateRange.startDate, dateRange.endDate, undefined, '[]');
  }

  searchFilterSelections(col: string, text: string) {
    if (!text) {
      const allItems = this.filterData.get(col) ?? [];
      const selectedFilters = this.filterOnSelect.get(col) ?? [];
      const allItemsWithState = allItems.map((item) => ({
        value: item,
        selected: selectedFilters.includes(item)
      }));
      this.filterAfterSearch.set(col, allItemsWithState);
      return;
    }

    const filterSearchResult = (this.filterData.get(col) ?? [])
      .filter((data) => data.toLocaleLowerCase().includes(text.toLowerCase()))
      .map((data) => ({
        value: data,
        selected: (this.filterOnSelect.get(col) ?? []).includes(data)
      }));

    this.filterAfterSearch.set(col, filterSearchResult);
  }

  clearSearchKeywords() {
    this.query = '';
    this.currentSearchQuery = '';
    this.loadVariants(true);
  }

  applyFilter() {
    this.result = [];
    for (const record of this.searchResult) {
      if (
        this.searchInFields(this.filterOnSelect.get('Viral Lineage') ?? [], record.viralLineageFull) &&
        (this.searchInFields(this.filterOnSelect.get('Viral Type') ?? [], record.viralAgentType) ||
          this.searchInFields(this.filterOnSelect.get('Viral Type') ?? [], record.viralProteinFullPartials)) &&
        this.searchInFields(this.filterOnSelect.get('Assay Type') ?? [], record.assayTypes) &&
        this.searchInFields(this.filterOnSelect.get('Data Provider') ?? [], record.dataProvider) &&
        this.searchInFields(this.filterOnSelect.get('Therapeutic Name') ?? [], record.drugNames) &&
        this.searchInFields(this.filterOnSelect.get('Therapeutic Class') ?? [], record.drugClasses) &&
        this.searchInFields(this.filterOnSelect.get('Mutation') ?? [], record.mutations) &&
        this.searchInFields(this.filterOnSelect.get('Data Source Type') ?? [], record.dataSourceTypes) &&
        this.searchDateRange(this.dateRange, record.dateReported)
      ) {
        this.result.push(record);
      }
    }

    this.totalVariants = this.result.length;
    this.ifNoResult();
  }

  ifNoResult() {
    if (this.result && this.result.length && this.DSDisplay) {
      this.noResult = false;
    } else {
      this.noResult = true;
    }
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.loadVariants(false);
  }

  getVLColor(vl: string): string {
    if (!this.vlList.includes(vl)) {
      this.vlList.push(vl);
    }
    if (this.vlList.indexOf(vl) > 19) {
      return '#000000';
    }
    return this.colorList[this.vlList.indexOf(vl)];
  }

  searchInFields(data: string[], record: string | any[]) {
    if (!data.length) {
      return true;
    }
    for (const d of data) {
      if (typeof record === 'string') {
        if (d.toLowerCase() === record.toLowerCase()) {
          return true;
        }
      } else {
        if (record.map((v) => v.toLowerCase()).includes(d.toLowerCase())) {
          return true;
        }
        if (record.map((v) => v.toLowerCase()).includes(('Other Variants: ' + d).toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  }

  loadColumnsFilterData(columnName: string, recordDB: string | any[]) {
    const dataList = this.filterData.get(columnName);
    const dataListSearch = this.filterAfterSearch.get(columnName);
    for (const vl of recordDB) {
      if (dataList) {
        dataList.push(vl);
      }
      if (dataListSearch) {
        dataListSearch.push(vl);
      }
    }
  }

  initalizeFilterData() {
    for (const col of this.filterColumn) {
      this.filterData.set(col, []);
      this.filterAfterSearch.set(col, []);
      this.filterExpanded.set(col, false);
    }
  }

  initalizeFilterOnSelect() {
    for (const col of this.filterColumn) {
      this.filterOnSelect.set(col, []);
    }
    this.filterOnSelect.set('Mutation', []);
  }

  andSearch(andQuery: string | any[], searchRecord: VariantDataset) {
    if (!andQuery || andQuery.length === 0) {
      return true;
    }
    if (typeof andQuery === 'string') {
      return this.search(andQuery, searchRecord);
    } else {
      let result = true;
      for (const andKeyword of andQuery) {
        result = result && this.search(andKeyword, searchRecord);
        if (!result) {
          return result;
        }
      }
      return result;
    }
  }

  orSearch(orQuery: string | any[], searchRecord: VariantDataset) {
    if (!orQuery || orQuery.length === 0) {
      return false;
    }

    if (typeof orQuery === 'string') {
      return this.search(orQuery, searchRecord);
    } else {
      let result = false;
      for (const orKeyword of orQuery) {
        result = result || this.search(orKeyword, searchRecord);
        if (result) {
          return result;
        }
      }
      return result;
    }
  }

  normalizeSearchWords(input: any): string {
    if (typeof input !== 'string') {
      return '';
    }
    return input.toLowerCase().normalize().replace(/\W/g, '');
  }

  search(searchKeyword: string, searchRecord: any): boolean {
    const searchWord = this.normalizeSearchWords(searchKeyword);
    if (!searchWord) {
      return false;
    }

    for (const field in searchRecord) {
      if (field === 'reportId' || field === 'dataFileDownloadUrl' || field === 'dataSourceUrls') {
        continue;
      }
      const fieldValue = searchRecord[field];
      if (typeof fieldValue === 'string') {
        if (this.normalizeSearchWords(fieldValue).includes(searchWord)) {
          return true;
        }
      } else if (Array.isArray(fieldValue)) {
        for (const item of fieldValue) {
          if (this.normalizeSearchWords(item).includes(searchWord)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  clearAllFilters() {
    this.filterStateService.clearFilterState(); // Clear all filter states
    this.dateRange = null; // Clear dateRange in the component state
    this.filterOnSelect = this.deepCopyMap(this.filterOnSelectOrig); // Use the deep copy function

    // Reset filterExpanded map
    this.filterExpanded.forEach((value, key) => {
      this.filterExpanded.set(key, false);
    });

    this.mutationExpanded = false;
    this.dateExpanded = false;

    this.loadVariants(true); // Reset paginator and load variants
  }

  removeFilter(key: string, value: string) {
    const dataList = this.filterOnSelect.get(key) ?? [];
    if (dataList.includes(value)) {
      const index = dataList.indexOf(value);
      dataList.splice(index, 1);
    }
    this.filterOnSelect.set(key, dataList);
    this.filterStateService.setFilterState(this.objectToMap(this.filterOnSelect));

    // Update the search filter selections to reflect changes in selection
    this.searchFilterSelections(key, '');
    this.loadVariants(true); // Reset paginator and load variants
  }

  displayFilterBy(): any[] {
    const filterArray: any[] = [];
    this.filterOnSelect.forEach((values, key) => {
      values.forEach((value) => {
        filterArray.push([key, value]);
      });
    });
    return filterArray;
  }

  handleOnclickEvent(event: string): void {
    this.display = false;
    this.keyWords = event;
  }

  handleFilterOnChangeEvent(event: any): void {
    this.keyWords = event;
    this.mutationFilterAutoComplete = []; // Clear the list before processing

    if (this.keyWords.length === 0) {
      this.mutationDisplay = false; // Hide the autocomplete list
      return;
    }

    this.mutationDisplay = true;
    const filteredData: string[] = [];
    for (const dataset of this.searchResult) {
      if (dataset.mutations) {
        for (const mutation of dataset.mutations) {
          if (
            mutation.toLowerCase().includes(this.keyWords.toLowerCase()) &&
            !this.mutationFilterAutoComplete.includes(mutation)
          ) {
            this.mutationFilterAutoComplete.push(mutation);
          }
        }
      }
    }
    this.mutationFilterAutoComplete.sort((a, b) => a.localeCompare(b));
    this.mutationFilterAutoComplete = this.mutationFilterAutoComplete.slice(0, 5);
  }

  filterAutoComplete(value: string) {
    this.mutationFilterAutoComplete = [];
    for (const m of this.mutationFilter) {
      if (m.toLowerCase().includes(value.toLocaleLowerCase())) {
        this.mutationFilterAutoComplete.push(m);
        if (this.mutationFilterAutoComplete.length > 4) {
          break;
        }
      }
    }
    if (this.mutationFilterAutoComplete.length === 0) {
      this.mutationFilterAutoComplete.push('No mutation found');
    }
  }

  getUpdateText(): string {
    const today = new Date();
    const date = new Date(this.latestUpdate);
    const diffTime = Math.abs(today.getTime() - date.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays === 1) {
      return '1 day ago';
    }
    return diffDays + ' days ago';
  }

  linkToAssay(assayType: string): string | null {
    if (this.assays) {
      for (const assay of this.assays) {
        if (assayType === assay.assayType) {
          return '/covid19/variant/assays/' + assay.id + '/' + assay.assayType;
        }
      }
    }
    return null;
  }

  getShareLink(): string {
    if (this.dataLoading || this.dateRange?.startDate === null || this.dateRange?.endDate === null) return '';

    const params: {[key: string]: string} = {};
    this.filterOnSelect.forEach((values, key) => {
      params[key.toLowerCase().replace(/ /g, '_')] = values.join(',');
    });

    if (this.dateRange) {
      params['start_date'] = this.dateRange.startDate.format('YYYY-MM-DD');
      params['end_date'] = this.dateRange.endDate.format('YYYY-MM-DD');
    }

    if (this.currentSearchQuery) {
      params['search_query'] = this.currentSearchQuery;
    }

    const queryString = new URLSearchParams(params).toString();
    return `${window.location.href.split('?')[0]}?${queryString}`;
  }

  copy() {
    this.displayCopied = true;
    setTimeout(() => {
      this.displayCopied = false;
    }, 2000);
  }

  objectToMap(obj: any): Map<string, any> {
    const map = new Map<string, any>();
    for (const key of Object.keys(obj)) {
      map.set(key, obj[key]);
    }
    return map;
  }

  processQueryParams(params: any) {
    const updatedFilterOnSelect = new Map(this.filterOnSelect);

    Object.keys(params).forEach((key) => {
      const paramKey = key.replace(/_/g, ' ');
      const paramValue = Array.isArray(params[key]) ? params[key] : [params[key]];

      if (paramKey === 'start date' || paramKey === 'end date') {
        if (!this.dateRange) {
          this.dateRange = {
            startDate: paramKey === 'start date' ? moment(params[key]) : moment(),
            endDate: paramKey === 'end date' ? moment(params[key]) : moment()
          };
        } else {
          this.dateRange = {
            ...this.dateRange,
            [paramKey === 'start date' ? 'startDate' : 'endDate']: moment(params[key])
          };
        }
        // Ensure dateExpanded is set to true when a date range is provided
        this.dateExpanded = true;
      } else if (paramKey === 'search query') {
        this.currentSearchQuery = params[key];
      } else if (paramKey === 'mutation') {
        const existingValues = this.filterOnSelect.get('Mutation') || [];
        paramValue.forEach((value: string) => {
          value.split(',').forEach((splitValue) => {
            if (splitValue.trim() !== '' && !existingValues.includes(splitValue.trim())) {
              existingValues.push(splitValue.trim());
            }
          });
        });
        updatedFilterOnSelect.set('Mutation', existingValues);
        this.mutationExpanded = true;
      } else if (this.filterColumn.includes(this.toTitleCase(paramKey))) {
        const existingValues = updatedFilterOnSelect.get(this.toTitleCase(paramKey)) || [];
        paramValue.forEach((value: string) => {
          value.split(',').forEach((splitValue) => {
            if (splitValue.trim() !== '' && !existingValues.includes(splitValue.trim())) {
              existingValues.push(splitValue.trim());
            }
          });
        });
        updatedFilterOnSelect.set(this.toTitleCase(paramKey), existingValues);
        this.filterExpanded.set(this.toTitleCase(paramKey), true);
      } else if (paramKey === 'id') {
        // Support LEGACY option of passing list of report_numbers as 'id' query parameter
        this.idList = paramValue.map((id: string) => parseInt(id, 10));
      }
    });

    this.filterOnSelect = updatedFilterOnSelect;
    this.filterStateService.setFilterState(this.objectToMap(this.filterOnSelect));

    // Update the UI with the query parameters
    this.filterOnSelect.forEach((values, key) => {
      this.searchFilterSelections(key, '');
    });

    this.loadVariants(true); // Reset paginator and load variants
  }

  toTitleCase(str: string): string {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
}
