import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AssayDetailsApiService} from '../../../services/api/assay-details-api/assay-details-api.service';

@Component({
  selector: 'app-single-assay',
  templateUrl: './single-assay.component.html',
  styleUrls: ['./single-assay.component.scss']
})
export class SingleAssayComponent implements OnInit {
  public drugId!: any;
  public error = false;
  public drug: any;
  public dataLoading = false;
  constructor(private route: ActivatedRoute, private drugNameApi: AssayDetailsApiService) {}

  ngOnInit(): void {
    this.drugId = this.route.snapshot.paramMap.get('id');
    this.error = false;
    this.dataLoading = true;
    this.drugNameApi.getSingleAssay(this.drugId).subscribe(
      (data) => {
        this.dataLoading = false;
        this.drug = data[0];
      },
      (error) => (this.error = true)
    );
  }
}
