<div class="tab-content active mb-4" id="assay_combination_data">
  <div class="row">
    <div class="col-md-12 h5">Combination Screening Background</div>
  </div>
  <div class="row">
    <div class="col-md-12">
      NCATS’s <a href="https://ncats.nih.gov/matrix/about" target="_blank">matrix combination screening platform</a> can
      rapidly test the effect of different drug combinations on cellular, molecular or biochemical processes that are
      relevant to a disease of interest, including COVID-19. To better elucidate the dose-dependent activities in
      two-dimensional space, we have developed a state-of-the-art, high-throughput drug combination screening system in
      6x6 or 10x10 dose matrix format using a highly integrated robotics system and custom data analysis software.
    </div>
  </div>

  <div class="matrix-info" id="matrix_list">
    <table *ngFor="let dc of drugCombinations; let i = index" class="table w-100">
      <tbody>
        <tr class="top-row">
          <td class="h5">
            Dataset {{ i + 1 }}: <a href="{{ dc.matrixlink }}" target="_blank">{{ dc.projectname }}</a>
          </td>
          <td>
            <a href="{{ dc.matrixlink }}" target="_blank"><button class="btn btn-sm btn-success">View Data</button></a>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <table class="table table-sm table-responsive table-borderless">
              <tbody>
                <tr *ngIf="dc?.cellline">
                  <td class="h6 info-item">Cell Line:</td>
                  <td>{{ dc.cellline }}</td>
                </tr>
                <tr *ngIf="dc?.detectiontype">
                  <td class="h6 info-item">Detection Type:</td>
                  <td>{{ dc.detectiontype }}</td>
                </tr>
                <tr *ngIf="dc?.numberofcombination">
                  <td class="h6 info-item">No. Unique Combinations:</td>
                  <td class="h6" style="color: #006478">{{ dc.numberofcombination }}</td>
                </tr>
                <tr *ngIf="dc?.datescreened">
                  <td class="h6 info-item">Date Screened:</td>
                  <td>{{ dc.datescreened }}</td>
                </tr>
              </tbody>
            </table>
            <div id="dataset1_details" class="experiment-info">
              <table class="table table-sm table-responsive table-borderless">
                <tbody>
                  <tr>
                    <td class="h6">Description:</td>
                  </tr>
                  <tr>
                    <td class="detail">
                      <a href="{{ dc.assay1link }}">{{ dc.assay1 }}<img src="/assets/images/pdf.png" /></a>
                    </td>
                  </tr>
                  <tr>
                    <td class="detail">
                      <a href="{{ dc.assay2link }}">{{ dc.assay2 }}<img src="/assets/images/pdf.png" /></a>
                    </td>
                  </tr>

                  <tr>
                    <td class="h6" *ngIf="dc?.associatedcounterscreens">Associated Counterscreens:</td>
                  </tr>
                  <tr>
                    <td class="detail" *ngIf="dc?.associatedcounterscreens">
                      <a href="{{ dc.associatedcounterscreenslink }}" target="_blank">{{
                        dc.associatedcounterscreens
                      }}</a>
                    </td>
                  </tr>

                  <tr>
                    <td class="h6" *ngIf="dc?.normalization">Normalization:</td>
                  </tr>
                  <tr>
                    <td class="detail" *ngIf="dc?.normalization">{{ dc.normalization }}</td>
                  </tr>

                  <tr>
                    <td class="h6" *ngIf="dc?.highlights">Highlights:</td>
                  </tr>
                  <tr>
                    <td class="detail" *ngIf="dc?.highlights">{{ dc.highlights }}</td>
                  </tr>

                  <tr>
                    <td class="h6" *ngIf="dc?.collaborators">Collaborators:</td>
                  </tr>
                  <tr>
                    <td class="detail" *ngIf="dc?.collaborators">{{ dc.collaborators }}</td>
                  </tr>

                  <tr>
                    <td class="h6" *ngIf="dc?.publication !== 'N/A'">Associated Publications:</td>
                  </tr>
                  <tr>
                    <td class="detail">
                      <a
                        *ngIf="dc?.publication !== 'N/A'"
                        href="{{ dc.publication }}"
                        target="_blank"
                        class="is-external-link"
                        >{{ dc.publication }}<i class="fa fa-external-link" aria-hidden="true"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
