<div class="tab-content active" id="assay_single_agent">
  <div class="row">
    <div class="col-md-6">
      <table class="assay-table">
        <tbody>
          <tr *ngIf="drug?.targetCategory">
            <td>Target Category</td>
            <td>{{ drug.targetCategory }}</td>
          </tr>
          <tr *ngIf="drug?.target">
            <td>Target</td>
            <td>{{ drug.target }}</td>
          </tr>
          <tr *ngIf="drug?.assayType">
            <td>Assay Type</td>
            <td>{{ drug.assayType }}</td>
          </tr>
          <tr *ngIf="drug?.cellLine">
            <td>Cell Line</td>
            <td>{{ drug.cellLine }}</td>
          </tr>
          <tr *ngIf="drug?.detectionType">
            <td>Detection Type</td>
            <td>{{ drug.detectionType }}</td>
          </tr>
          <tr *ngIf="drug?.dateScreened">
            <td>Date Screened</td>
            <td>{{ drug.dateScreened }}</td>
          </tr>
          <tr *ngIf="drug?.throughpu">
            <td>Throughput</td>
            <td>{{ drug.throughput }}</td>
          </tr>
          <tr *ngIf="drug?.status">
            <td>Status</td>
            <td>{{ drug.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-6">
      <!-- <a [href]="drug?.assayDataFileUrl"><button class="btn btn-primary" onclick="">DOWNLOAD ASSAY PROTOCOL</button></a> -->
      <a [href]="downloadScreeningData"><button class="btn btn-success">EXPORT SCREENING DATA</button></a>
    </div>
  </div>
  <div class="row" *ngIf="drug?.assayOverview">
    <div class="col-md-12 h6">Assay Overview</div>
  </div>
  <div class="row" *ngIf="drug?.assayOverview">
    <div class="col-md-12" [innerHTML]="drug?.assayOverview | SafeHtml"></div>
  </div>

  <div class="row" *ngIf="drug?.protocolDescription">
    <div class="col-md-12 h6">Protocol</div>
  </div>
  <div class="row" *ngIf="drug?.assayOverview">
    <div class="col-md-12" [innerHTML]="drug?.protocolDescription | SafeHtml"></div>
  </div>

  <div class="row" *ngIf="drug?.interpretation">
    <div class="col-md-12 h6">Normalization/Assay interpretation</div>
  </div>
  <div class="row" *ngIf="drug?.assayOverview">
    <div class="col-md-12" [innerHTML]="drug?.interpretation | SafeHtml"></div>
  </div>
</div>
