import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AssayDetailsQL} from '../../../queries/assayDetails-query';
import {map, Observable} from 'rxjs';
import {AssayDetails} from '../../../models/dtos/assay-details/assay-details';
import {API_URLS, Endpoints} from '@odp/shared';
import {HttpLink} from 'apollo-angular/http';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {AssayDetailsEdge, AssayDetailsQuery} from '../../../models/dtos/assay-details/assay-details-ql';
import {SingleAssayDetails} from '../../../models/dtos/assay-details/single-assay-detail';
import {
  SingleAssayDetailsQuery,
  SingleAssayDetailsEdge
} from '../../../models/dtos/assay-details/single-assay-details-ql';
import {MatrixDetailsEdge, MatrixDetailsQuery} from '../../../models/dtos/assay-details/matrix-details.ql';
import {MatrixDetails} from '../../../models/dtos/assay-details/matrix-details';
import {AssayPerformanceDetails} from '../../../models/dtos/assay-details/assay-performance';
import {
  AssayPerformanceDetailsNode,
  AssayPerformanceDetailsQuery
} from '../../../models/dtos/assay-details/assay-performance.ql';
import {InMemoryCache} from '@apollo/client/core';
import toTitleCase from 'to-title-case';

@Injectable({
  providedIn: 'root'
})
export class AssayDetailsApiService {
  private variantBaseUrl!: string;
  configService: any;

  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private assayDetailsQL: AssayDetailsQL
  ) {
    this.variantBaseUrl = configuration.variantApiUrl;
    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getAssayDetails(): Observable<AssayDetails[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<AssayDetailsQuery>({query: this.assayDetailsQL.document})
      .pipe(
        map((result: {data: AssayDetailsQuery}) => {
          return this.mapAssayDetailsToEntry(result.data.cov2assays.edges);
        })
      );
  }

  public getSingleAssay(id: number): Observable<SingleAssayDetails[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<SingleAssayDetailsQuery>({query: this.assayDetailsQL.getSingleAssay, variables: {id: id}})
      .pipe(
        map((result: {data: SingleAssayDetailsQuery}) => {
          return this.mapSingleAssayDetailsToEntry(result.data.cov2assays.edges);
        })
      );
  }

  public getMatrixbyID(id: number): Observable<MatrixDetails[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<MatrixDetailsQuery>({query: this.assayDetailsQL.getMatrix, variables: {id: id}})
      .pipe(
        map((result: {data: MatrixDetailsQuery}) => {
          return this.mapMatrixDetailsToEntry(result.data.matricess.edges);
        })
      );
  }

  public getAssayPerformanceByAssayIDLibraryID(
    assayID: number,
    libraryID: number
  ): Observable<AssayPerformanceDetails[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<AssayPerformanceDetailsQuery>({
        query: this.assayDetailsQL.getAssayPerformance,
        variables: {assayID: assayID, libraryID: libraryID}
      })
      .pipe(
        map((result: {data: AssayPerformanceDetailsQuery}) => {
          return this.mapPerformanceDetailsToEntry(result.data.getAssayPerformanceByAssayIDLibraryID);
        })
      );
  }

  public mapAssayDetailsToEntry(assayEntry: AssayDetailsEdge[]): AssayDetails[] {
    const enteries = assayEntry.map((e) => {
      const assays: AssayDetails = {
        id: e.node.id,
        assayID: e.node.assayID,
        assayName: e.node.assayName,
        assayType: toTitleCase(e.node.assayType),
        category: toTitleCase(e.node.category),
        detectionType: toTitleCase(e.node.detectionType),
        active: e.node.active,
        doi: e.node.doi,
        assayDataFileUrl: e.node.assayDataFileUrl
      };
      return assays;
    });
    return enteries;
  }

  public mapPerformanceDetailsToEntry(assayEntry: AssayPerformanceDetailsNode[]): AssayPerformanceDetails[] {
    const enteries = assayEntry.map((e) => {
      const assays: AssayPerformanceDetails = {
        id: e.id,
        assayName: e.assayName,
        libraryId: e.libraryId,
        assayProtocolId: e.assayProtocolId,
        protocolName: e.protocolName,
        plateInfoId: e.plateInfoId,
        plateId: e.plateId,
        typeIndex: e.typeIndex,
        bI: e.bI,
        cvDmso: e.cvDmso,
        zNi: e.zNi
      };
      return assays;
    });
    return enteries;
  }

  public mapSingleAssayDetailsToEntry(assayEntry: SingleAssayDetailsEdge[]): SingleAssayDetails[] {
    const enteries = assayEntry.map((e) => {
      const assays: SingleAssayDetails = {
        id: e.node.id,
        assayName: e.node.assayName,
        assayID: e.node.assayID,
        targetCategory: e.node.targetCategory,
        target: e.node.target,
        assayType: e.node.assayType,
        detectionType: e.node.detectionType,
        dateScreened: e.node.dateScreened,
        throughput: e.node.throughput,
        assayOverview: e.node.assayOverview,
        protocolDescription: e.node.protocolDescription,
        interpretation: e.node.interpretation,
        assayDataFileUrl: e.node.assayDataFileUrl,
        assayProtocolFile: e.node.assayProtocolFile,
        assayScreeningFile: e.node.assayScreeningFile,
        matrixFileId: e.node.matrixFileId,
        cov2protocol: e.node.cov2protocol
      };
      return assays;
    });
    return enteries;
  }

  public mapMatrixDetailsToEntry(assayEntry: MatrixDetailsEdge[]): MatrixDetails[] {
    const enteries = assayEntry.map((e) => {
      const assays: MatrixDetails = {
        id: e.node.id,
        matrixid: e.node.matrixid,
        matrixlink: e.node.matrixlink,
        projectname: e.node.projectname,
        associatedcounterscreens: e.node.associatedcounterscreens,
        associatedcounterscreenslink: e.node.associatedcounterscreenslink,
        cellline: e.node.cellline,
        detectiontype: e.node.detectiontype,
        numberofcombination: e.node.numberofcombination,
        datescreened: e.node.datescreened,
        collaborators: e.node.collaborators,
        assay1: e.node.assay1,
        assay1link: e.node.assay1link,
        assay2: e.node.assay2,
        assay2link: e.node.assay2link,
        normalization: e.node.normalization,
        highlights: e.node.highlights,
        publication: e.node.publication,
        createdBy: e.node.createdBy,
        updatedBy: e.node.updatedBy,
        createdAt: e.node.createdAt,
        updatedAt: e.node.updatedAt
      };
      return assays;
    });
    return enteries;
  }
}
