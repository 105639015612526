import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Gs441524StudiesComponent} from './gs441524-studies/gs441524-studies.component';
import {RouterModule} from '@angular/router';
import {MatTabsModule as MatTabsModule} from '@angular/material/tabs';
import {MdbTabsModule} from 'mdb-angular-ui-kit/tabs';
import {HighchartsChartModule} from 'highcharts-angular';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {CurveClassesComponent} from './curve-classes/curve-classes.component';
import {DataBrowserComponent} from './data-browser/data-browser.component';
import {DataBrowserSamplesComponent} from './data-browser-samples/data-browser-samples.component';
import {DataBrowserSummaryComponent} from './data-browser-summary/data-browser-summary.component';
import {BackgroundComponent} from './data-browser-summary/background/background.component';
import {SingleAgentComponent} from './data-browser-summary/single-agent/single-agent.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '@odp/shared';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '../../pipes';

@NgModule({
  declarations: [
    Gs441524StudiesComponent,
    CurveClassesComponent,
    DataBrowserComponent,
    DataBrowserSamplesComponent,
    DataBrowserSummaryComponent,
    BackgroundComponent,
    SingleAgentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    MatTabsModule,
    MdbTabsModule,
    HighchartsChartModule,
    AutocompleteLibModule,
    NgxPaginationModule,
    NgbPopoverModule,
    CoreModule
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class ScreeningDataModule {}
