import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';
import {he} from 'date-fns/locale';

@Component({
  selector: 'app-library-chart',
  templateUrl: './library-chart.component.html',
  styleUrls: ['./library-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LibraryChartComponent implements OnInit {
  @Input() chartSetting: any = {data: [], name: '', color: '', min: null, max: null};

  public highcharts = Highcharts;

  chartOptions: any = {
    chart: {
      type: 'line',
      height: 250
    }
  };

  ngOnInit(): void {
    this.chartOptions = {
      chart: {
        type: 'line',
        height: 250
      },
      title: {
        text: this.chartSetting.name,
        style: {fontSize: '14px'}
      },

      tooltip: {
        // @ts-ignore
        formatter: function () {
          // @ts-ignore
          return this.y;
        }
      },
      yAxis: {
        title: {text: 'value'},
        min: this.chartSetting.min,
        max: this.chartSetting.max
      },

      xAxis: {
        title: {text: 'Plate number', margin: 15},
        tickWidth: 1,
        tickmarkPlacement: 'on',
        crosshair: true,
        labels: {
          enabled: false
        }
      },
      plotOptions: {
        series: {
          color: this.chartSetting.color,
          lineWidth: 1,
          marker: {
            radius: 2.5
          }
        }
      },

      series: [{name: this.chartSetting.data, data: this.chartSetting.data, showInLegend: false}],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }
        ]
      }
    };
  }
}
