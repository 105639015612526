import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AssayDetailsApiService} from '../../../../services/api/assay-details-api/assay-details-api.service';

@Component({
  selector: 'app-single-agent',
  templateUrl: './single-agent.component.html',
  styleUrls: ['./single-agent.component.scss']
})
export class SingleAgentAssayComponent implements OnInit {
  public drugId!: number;
  public error = false;
  public drug: any;
  public downloadScreeningData!: string;
  constructor(private route: ActivatedRoute, private drugNameApi: AssayDetailsApiService) {}

  ngOnInit(): void {
    this.drugId = this.route.snapshot.pathFromRoot['4'].params['id'];
    this.error = false;
    this.drugNameApi.getSingleAssay(this.drugId).subscribe(
      (data: any[]) => {
        this.drug = data[0];
        this.downloadScreeningData = this.getProcessedUrl(this.drug.assayDataFileUrl);
      },
      () => (this.error = true)
    );
  }

  getProcessedUrl(params: any): string {
    if (params) {
      return params.replace(/ /g, '_');
    }
    return '';
  }
}
