import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AssayDetailsApiService} from 'projects/covid19/src/lib/services/api/assay-details-api/assay-details-api.service';
import * as _ from 'lodash';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-libraries',
  templateUrl: './libraries.component.html',
  styleUrls: ['./libraries.component.scss']
})
export class LibrariesScreenComponent implements OnInit {
  public drugId!: number;
  public error = false;
  public libraries: any;
  public librariesCharts: any = [];
  constructor(private route: ActivatedRoute, private drugNameApi: AssayDetailsApiService) {}

  ngOnInit(): void {
    this.drugId = this.route.snapshot.pathFromRoot['4'].params['id'];
    this.error = false;

    this.drugNameApi.getSingleAssay(this.drugId).subscribe(
      (data: any[]) => {
        this.libraries = data[0].cov2protocol.edges.map((l: any) => {
          return {
            name: l.node.libraryId.library,
            id: l.node.libraryId.libraryID,
            short: l.node.libraryId.libraryShort,
            description: l.node.libraryId.libraryDescription
          };
        });

        const endpoints = this.libraries.map((l: any) => {
          return this.drugNameApi.getAssayPerformanceByAssayIDLibraryID(Number(this.drugId), l.id);
        });

        forkJoin(endpoints).subscribe((result: any) => {
          result.forEach((chartData: any, i: number) => {
            const l = this.libraries[i];
            this.librariesCharts.push({
              name: l.name,
              id: l.id,
              short: l.short,
              description: l.description,
              bI: chartData.map((x: any) => {
                return x.bI;
              }),
              zNi: chartData.map((x: any) => {
                return x.zNi;
              }),
              cvDmso: chartData.map((x: any) => {
                return x.cvDmso;
              })
            });
          });
        });
      },
      () => (this.error = true)
    );
  }
}
