<div class="text-center" *ngIf="librariesCharts.length === 0">
  <h3>Under Development</h3>
</div>
<div *ngIf="librariesCharts" class="tab-content active" id="assay_libs_screened">
  <div *ngFor="let library of librariesCharts; let i = index">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <strong>{{ library.name }}</strong>
          </div>
          <div class="col-md-12"><i>Screening complete</i></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12"><strong>About library</strong></div>
    </div>
    <div class="row">
      <div class="col-md-12" [innerHTML]="library.description | SafeHtml"></div>
    </div>

    <div class="row">
      <div *ngIf="library.bI" class="col-4">
        <app-library-chart [chartSetting]="{data: library.bI, name: 'S:B Ratio', color: '#325aa8'}"></app-library-chart>
      </div>
      <div *ngIf="library.zNi" class="col-4">
        <app-library-chart
          [chartSetting]="{data: library.zNi, name: 'Z’-factor', color: '#ff4800', min: 0, max: 1}"
        ></app-library-chart>
      </div>
      <div *ngIf="library.cvDmso" class="col-4">
        <app-library-chart
          [chartSetting]="{data: library.cvDmso, name: 'DMSO CV%', color: '#45853e'}"
        ></app-library-chart>
      </div>
    </div>
    <br />
    <br />
  </div>
</div>
